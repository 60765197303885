import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import store from "./core/redux/store";
import LoginScreen from "./screens/login/LoginScreen";
import {Provider} from "react-redux";
import LeftMenu from "./common/LeftMenu";
import ChatbotSelection from "./common/ChatbotSelection";
import ChatRecords from "./screens/records/ChatRecords";
import AnalyticsScreen from "./screens/analytics/AnalyticsScreen";
import ConfigTabs from "./screens/config/ConfigTabs";
import ChatDetails from "./screens/records/ChatDetails";
import InboxScreen from "./screens/inbox/InboxScreen";
import NotificationScreen from "./screens/notification/NotificationScreen";
const Container = styled.div`
    background-color: #0B261C;
    color: #ffffff;
    min-height: 100vh;
    /* padding: 20px; */
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
`;
const MainContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const BoxedContent = styled.div`
    width: 100%; /* Adjust width as necessary */
    max-width: 100%;
`;

const App = () => {


    const [authToken, setAuthToken] = useState(() => localStorage.getItem("authToken"));

    // 2) Define a callback that your LoginScreen can use on successful login
    const onLoginSuccess = (token) => {
        localStorage.setItem("authToken", token); // store in localStorage
        setAuthToken(token);                      // update React state -> triggers re-render
    };

    if(authToken){
        return    <MainContainer>
        <LeftMenu onLoginSuccess={onLoginSuccess} />
        <ChatbotSelection />
                <Routes>
                    <Route path="/history" element={<ChatRecords />} />
                    <Route path="/details" element={<ChatDetails />} />
                    <Route path="/analytics" element={<AnalyticsScreen />} />
                    <Route path="/config" element={<ConfigTabs />} />
                    <Route path="/inbox" element={<InboxScreen />} />
                    <Route path="/notification" element={<NotificationScreen />} />
                </Routes>
        </MainContainer>

    }
    return (
        <Container>
            <ToastContainer />
            <BoxedContent>
                <Routes>
                    <Route path="/login" element={<LoginScreen  onLoginSuccess={onLoginSuccess} />} />
                    <Route path="/" element={<LoginScreen  onLoginSuccess={onLoginSuccess} />} />
                </Routes>
            </BoxedContent>
        </Container>
    );
};

const AppWrapper = () => {


    return (
        <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    );
};

export default AppWrapper;
