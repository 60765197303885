import React from 'react';
import styled from 'styled-components';

const SelectionContainer = styled.div`
  flex: 1;
  padding: 2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const ChatbotSelection = () => {
    const owner = localStorage.getItem('owner');
if(owner && owner.includes("casino")){
    return (
        <SelectionContainer>
            <Header>Chatbot Selection</Header>
            <Dropdown>
                <option>LAILA</option>
            </Dropdown>
        </SelectionContainer>
    );
}
    return (
        <SelectionContainer>
            <Header>Chatbot Selection</Header>
            <Dropdown>
                <option>CDE</option>
            </Dropdown>
        </SelectionContainer>
    );
};

export default ChatbotSelection;
