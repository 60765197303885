import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Constants from "../../common/Constants";

const Container = styled.div`
  padding: 2rem;
  background-color: #fafafa;
  min-height: 100vh;
    flex:3
`;

const TabMenu = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
`;

const Tab = styled.div`
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  border-bottom: ${({ active }) => (active ? '2px solid #007bff' : 'none')};
  color: ${({ active }) => (active ? '#007bff' : '#333')};
`;

const ColorRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`;

const ColorGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0; /* Remove margin for the last item */
    }

    input {
        margin-top: 0.5rem;
        width: 100%; /* Full width of the group */
    }
`;

const TabContent = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex:3
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const Slider = styled.input`
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${({ primary }) => (primary ? '#007bff' : '#fff')};
  color: ${({ primary }) => (primary ? '#fff' : '#333')};
  border: ${({ primary }) => (primary ? 'none' : '1px solid #ddd')};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ primary }) => (primary ? '#0056b3' : '#f5f5f5')};
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #007bff;
`;
const ConfigTabs = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [instructions, setInstructions] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch records from the backend
        const fetchRecords = async () => {
            try {

                setIsLoading(true);
                const owner = localStorage.getItem('owner');
                const response = await axios.get(Constants.serverlink+'/info?owner='+owner);
                setInstructions(response.data?.instructions || '');
            } catch (error) {
                console.error('Error fetching records:', error);
            }finally {
                setIsLoading(false);
            }
        };

        fetchRecords();
    }, []);

    const submitInfo = async () => {
        try {
            setIsLoading(true);
            const payload = {
                instructions,
            };

            const response = await axios.post(Constants.serverlink+'/updateinfo', payload);
            console.log('Response:', response.data);

            alert('Instructions updated successfully!');
        } catch (error) {
            console.error('Error submitting instructions:', error);
            alert('Failed to update instructions.');
        }
        setIsLoading(false);
    };

    const renderContent = () => {

        if (isLoading) {
            return <LoadingIndicator>Loading...</LoadingIndicator>;
        }

        switch (activeTab) {
            case 1:
                return (
                    <TabContent>
                        <h3>Chatbot Personality</h3>
                        <p>
                            Tailor your chatbot's appearance and personality to your brand. Customization applies when sharing your chatbot externally or embedding it on a website.
                        </p>
                        <FormGroup>
                            <Label>Personality Instructions</Label>
                            <TextArea
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                            />
                        </FormGroup>
                        <Checkbox>
                            <input type="checkbox" id="internet" />
                            <Label htmlFor="internet" style={{ marginLeft: '0.5rem' }}>
                                Open to internet
                            </Label>
                        </Checkbox>
                        <FormGroup>
                            <Label>Chatbot Creativity</Label>
                            <Slider type="range" min="0" max="1" step="0.1" defaultValue="0.5" />
                        </FormGroup>
                        <ButtonGroup>
                            <Button onClick={submitInfo} primary>Save</Button>
                        </ButtonGroup>
                    </TabContent>
                );
            // Other tabs can follow here...
            default:
                return null;
        }
    };

    return (
        <Container>
            <TabMenu>
                <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                    Chatbot Personality
                </Tab>
            </TabMenu>
            <div style={{ flex: 3 }}>
                {renderContent()}
            </div>
        </Container>
    );
};

export default ConfigTabs;
