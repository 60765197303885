import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faComments,
    faHistory,
    faCog,
    faChartBar,
    faInbox,
    faBell,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import axios from "axios";
import Constants from "./Constants";

const MenuContainer = styled.div`
    width: 80px;
    height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    border-right: 1px solid #e0e0e0;
`;

const MenuItem = styled(Link)`
    margin: 1rem 0;
    width: 40px;
    height: 40px;
    background-color: ${({ active }) => (active ? '#007bff' : '#fff')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;

    &:hover {
        background-color: #e0e0e0;
    }
`;


const CounterItem = styled.div`
    position: absolute;
    background: red;
    color: white;
    font-weight: bold;
    width: 15px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    height: 15px;
    padding-top: 2px;
    margin-top: -31px;
    margin-left: 29px;
`;

const LeftMenu = ({onLoginSuccess}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [counter, setCounter] = useState(0);

    const handleLogout = () => {
        // Example: Clear auth token from localStorage
        // localStorage.removeItem('authToken');
        // Navigate to login page
        onLoginSuccess(false)
        localStorage.removeItem("authToken");
        navigate('/login');
    };
    useEffect(() => {
        // Fetch records from the backend
        const fetchRecords = async () => {
            try {
                const owner = localStorage.getItem('owner');
                const response = await axios.get(Constants.serverlink+'/notification?owner='+owner);

                if(response.status==200){
                    const _counter = response.data.filter(x=>!x.read) || []
                    setCounter(_counter.length)
                }
                setTimeout(()=>{
                    fetchRecords()
                },4000)
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, []);
    return (
        <MenuContainer>
            <MenuItem to="/history" active={location.pathname === '/history'}>
                <FontAwesomeIcon icon={faComments} color={location.pathname === '/history' ? "#fff" : "#333"} />
            </MenuItem>
            <MenuItem to="/analytics" active={location.pathname === '/analytics'}>
                <FontAwesomeIcon icon={faChartBar} color={location.pathname === '/analytics' ? "#fff" : "#333"} />
            </MenuItem>
            <MenuItem to="/inbox" active={location.pathname === '/inbox'}>
                <FontAwesomeIcon icon={faInbox} color={location.pathname === '/inbox' ? "#fff" : "#333"} />
            </MenuItem>

            <MenuItem to="/notification" active={location.pathname === '/notification'}>
                {counter>0 &&<CounterItem>{counter}</CounterItem>}
                <FontAwesomeIcon icon={faBell} color={location.pathname === '/notification' ? "#fff" : "#333"} />
            </MenuItem>

            <MenuItem
                as="div"            // Override Link behavior; or you could just do to="/logout" if you have a route
                onClick={handleLogout}
                style={{ marginTop: 'auto' }} // push to the bottom if desired
            >
                <FontAwesomeIcon
                    icon={faSignOutAlt}
                    color="#333"
                />
            </MenuItem>
        </MenuContainer>
    );
};

export default LeftMenu;
