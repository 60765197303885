import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faPlayCircle,
    faPauseCircle
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Constants from "../../common/Constants";

const ChatContainer = styled.div`
    padding: 2rem;
    background-color: #fafafa;
    min-height: 80vh;
    display: flex;
    flex: 3;
    flex-direction: column;
`;

const Header = styled.div`
    text-align: center;
    margin-bottom: 1.5rem;
`;

const Title = styled.h2`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
`;

const SubTitle = styled.p`
    color: #999;
`;

const ChatWindow = styled.div`
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1rem;
`;

const MessageRow = styled.div`
    display: flex;
    justify-content: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
`;

const MessageBubble = styled.div`
    max-width: 60%;
    background-color: ${({ isUser }) => (isUser ? '#007bff' : '#f1f1f1')};
    color: ${({ isUser }) => (isUser ? '#fff' : '#333')};
    padding: 0.75rem 1rem;
    border-radius: ${({ isUser }) => (isUser ? '16px 16px 0 16px' : '16px 16px 16px 0')};
    font-size: 0.9rem;
    position: relative;
`;

const Timestamp = styled.span`
    font-size: 0.7rem;
    color: #999;
    margin-top: 0.25rem;
    text-align: ${({ isUser }) => (isUser ? 'right' : 'left')};
`;

const FlagButton = styled.div`
    position: absolute;
    top: -10px;
    right: -10px;
    color: red;
    cursor: pointer;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
`;

const ActionButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: ${({ primary }) => (primary ? '#000' : '#fff')};
    color: ${({ primary }) => (primary ? '#fff' : '#333')};
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: ${({ primary }) => (primary ? '#333' : '#f5f5f5')};
    }
`;

const PlayButton = styled.div`
    margin-top: 0.5rem;
    cursor: pointer;

    svg {
        font-size: 1.3rem;
        color: ${({ isUser }) => (isUser ? '#fff' : '#333')};
    }
`;

const ChatDetails = () => {
    const params = new URLSearchParams(window.location.search);
    const threadId = params.get('threadId');
    const [messages, setMessages] = useState([]);
    const [threadTitle, setThreadTitle] = useState('Chat conversation history.');

    // ID of the message currently playing (or null if none)
    const [playingMessageId, setPlayingMessageId] = useState(null);
    // A single Audio ref to handle playback
    const audioRef = useRef(null);

    useEffect(() => {
        // Fetch the messages for the threadId
        const fetchMessages = async () => {
            try {
                const owner = localStorage.getItem('owner');
                const response = await axios.get(Constants.serverlink + `/listThreads/${threadId}?owner=`+owner);
                const data = response.data;

                let formattedMessages = data.map((item) => ({
                    id: item._id,
                    question: item.message,
                    response: item.reply,
                    timestamp: new Date(item.updatedAt).toLocaleString(),
                    flagged: item.flagged || false,
                    isAudio: item.isAudio || false,
                    audioUrl: item.audio || ''
                }));

                formattedMessages = formattedMessages.reverse();

                setMessages(formattedMessages);
                setThreadTitle(`Thread: ${threadId}`);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [threadId]);

    const handleFlagMessage = async (messageId) => {
        try {
            const response = await axios.patch(Constants.serverlink + `/flagMessage/${messageId}`);
            if (response.status === 200) {
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === messageId ? { ...msg, flagged: !msg.flagged } : msg
                    )
                );
            }
        } catch (error) {
            console.error('Error flagging message:', error);
        }
    };

    const handleSaveConversation = () => {
        // Create CSV header
        const csvHeader = ["Sender,Receiver,Time"];

        // Map each message to a CSV row
        const csvRows = messages.map((msg) => {
            const sender = `"${msg.question.replace(/"/g, '""')}"`;
            const receiver = `"${msg.response.replace(/"/g, '""')}"`;
            const time = `"${msg.timestamp}"`;
            return [sender, receiver, time].join(",");
        });

        const csvString = [csvHeader.join(","), ...csvRows].join("\n");
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `conversation-thread-${threadId}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * Toggles play/pause for a given audio message.
     * If the clicked message is already playing, we pause it.
     * Otherwise, we stop any current audio and play the new one.
     */
    const handleTogglePlay = (messageId, audioPath) => {
        // Full URL to your audio file
        const fullUrl = `${Constants.serverlink}/${audioPath}`;

        // If the user clicked on the already playing message => PAUSE
        if (playingMessageId === messageId) {
            // Pause existing audio
            if (audioRef.current) {
                audioRef.current.pause();
            }
            setPlayingMessageId(null);
        } else {
            // If another message was playing, pause it
            if (audioRef.current) {
                audioRef.current.pause();
            }
            // Create a new audio instance & play
            const newAudio = new Audio(fullUrl);
            audioRef.current = newAudio;
            newAudio.play().catch((err) => {
                console.error("Error playing audio:", err);
            });
            setPlayingMessageId(messageId);
        }
    };

    return (
        <ChatContainer>
            <Header>
                <Title>{threadTitle}</Title>
                <SubTitle>Conversation details for thread {threadId}.</SubTitle>
            </Header>

            <ChatWindow>
                {messages.map((msg) => (
                    <React.Fragment key={msg.id}>
                        <MessageRow isUser={false}>
                            <MessageBubble isUser={false}>{msg.question}</MessageBubble>
                        </MessageRow>

                        <MessageRow isUser={true}>
                            <MessageBubble isUser={true}>
                                {msg.response}
                                {/* Flag Button */}
                                <FlagButton onClick={() => handleFlagMessage(msg.id)}>
                                    <FontAwesomeIcon
                                        icon={faFlag}
                                        color={msg.flagged ? 'red' : 'gray'}
                                    />
                                </FlagButton>

                                {/* Play/Pause Button if isAudio is true */}
                                {msg.isAudio && (
                                    <PlayButton
                                        isUser={true}
                                        onClick={() => handleTogglePlay(msg.id, msg.audioUrl)}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                playingMessageId === msg.id
                                                    ? faPauseCircle
                                                    : faPlayCircle
                                            }
                                        />
                                    </PlayButton>
                                )}
                            </MessageBubble>
                        </MessageRow>

                        <Timestamp isUser={true}>{msg.timestamp}</Timestamp>
                    </React.Fragment>
                ))}
            </ChatWindow>

            <ActionContainer>
                <ActionButton primary onClick={handleSaveConversation}>
                    Save Full Conversation Log
                </ActionButton>
            </ActionContainer>
        </ChatContainer>
    );
};

export default ChatDetails;
