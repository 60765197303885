import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faUsers, faComments, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title as ChartTitle,
    Tooltip,
    Legend,
} from 'chart.js';
import Constants from "../../common/Constants";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartTitle, Tooltip, Legend);

const AnalyticsContainer = styled.div`
    padding: 2rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
`;

const Title = styled.h2`
    font-size: 1.5rem;
    color: #333;
`;

const Button = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: #333;
    }
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
`;

const Card = styled.div`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const IconContainer = styled.div`
    background-color: #f5f5f5;
    border-radius: 50%;
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Metric = styled.h3`
    font-size: 2rem;
    margin: 0;
    color: #333;
`;

const ChartContainer = styled.div`
    margin-top: 1rem;
`;

const AnalyticsScreen = () => {
    const [stats, setStats] = useState({
        totalMessages: 0,
        totalDistinctThreads: 0,
    });

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Messages',
                data: [],
                backgroundColor: '#007bff',
            },
        ],
    });

    useEffect(() => {
        // Fetch stats and chart data from the backend
        const fetchStats = async () => {
            try {
                const owner = localStorage.getItem('owner');
                const statsResponse = await axios.get(Constants.serverlink+'/stats?owner='+owner);
                const chartResponse = await axios.get(Constants.serverlink+'/statsByDay?owner='+owner);

                setStats({
                    totalMessages: statsResponse.data.totalMessages,
                    totalDistinctThreads: statsResponse.data.totalDistinctThreads,
                });

                setChartData({
                    labels: chartResponse.data.map((day) => day.date),
                    datasets: [
                        {
                            label: 'Messages',
                            data: chartResponse.data.map((day) => day.count),
                            backgroundColor: '#007bff',
                        },
                    ],
                });
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, []);

    return (
        <AnalyticsContainer>
            <Header>
                <Title>Insights</Title>

            </Header>

            <CardsContainer>
                <Card>
                    <CardHeader>
                        <IconContainer>
                            <FontAwesomeIcon icon={faCoins} />
                        </IconContainer>
                        <div>
                            <h4>Credits used</h4>
                            <Metric>0</Metric>
                        </div>
                    </CardHeader>
                    <ChartContainer>
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    </ChartContainer>
                </Card>
                <Card>
                    <CardHeader>
                        <IconContainer>
                            <FontAwesomeIcon icon={faUsers} />
                        </IconContainer>
                        <div>
                            <h4>Unique users</h4>
                            <Metric>{stats.totalDistinctThreads}</Metric>
                        </div>
                    </CardHeader>
                    <ChartContainer>
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    </ChartContainer>
                </Card>
                <Card>
                    <CardHeader>
                        <IconContainer>
                            <FontAwesomeIcon icon={faComments} />
                        </IconContainer>
                        <div>
                            <h4>Conversations</h4>
                            <Metric>{stats.totalDistinctThreads}</Metric>
                        </div>
                    </CardHeader>
                    <ChartContainer>
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    </ChartContainer>
                </Card>
                <Card>
                    <CardHeader>
                        <IconContainer>
                            <FontAwesomeIcon icon={faMessage} />
                        </IconContainer>
                        <div>
                            <h4>Messages</h4>
                            <Metric>{stats.totalMessages}</Metric>
                        </div>
                    </CardHeader>
                    <ChartContainer>
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    </ChartContainer>
                </Card>
            </CardsContainer>
        </AnalyticsContainer>
    );
};

export default AnalyticsScreen;
