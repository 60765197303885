import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faFlag, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Constants from "../../common/Constants";

const RecordsContainer = styled.div`
    flex: 3;
    padding: 2rem;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Header = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333;
`;




const RecordList = styled.div`
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const RecordInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #282c34;
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
`;

const PageButton = styled.button`
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }

    &:disabled {
        cursor: not-allowed;
        color: #999;
    }
`;

const NotificationScreen = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch records from the backend
        const fetchRecords = async () => {
            try {
                const owner = localStorage.getItem('owner');
                const response = await axios.get(Constants.serverlink+'/notification?owner='+owner);
                setRecords(response.data);
                axios.get(Constants.serverlink+'/markasreadnotification?owner='+owner);
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, []);

    return (
        <RecordsContainer>
            <Header>Notification Records</Header>

            {records.map((record, index) => (
                <RecordList key={index} onClick={() => navigate(`/details?threadId=${record.threadId}`)}>
                    <RecordInfo>
                        <FontAwesomeIcon icon={faCommentDots} />
                        <span>{record.message}</span>
                    </RecordInfo>
                </RecordList>
            ))}
            <Pagination>
                <PageButton disabled>{'<'}</PageButton>
                <PageButton disabled>1</PageButton>
                <PageButton>{'>'}</PageButton>
            </Pagination>
        </RecordsContainer>
    );
};

export default NotificationScreen;
