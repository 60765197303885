import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Constants from "../../common/Constants";

// Styled Components
const AppContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
`;

const LoginContainer = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #333;
`;

const Input = styled.input`
    width: 94%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
`;

const Button = styled.button`
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 0.5rem;
  text-align: center;
`;

const LoginScreen = ({ onLoginSuccess }) => {
    const navigate = useNavigate();

    // State for email, password, and error display
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // clear any previous error


        try {

            let payload = {
                email,password,
            }
            const response = await axios.post(Constants.serverlink+'/login', payload);

            debugger
            if(response && response.data && response.data.owner){
                localStorage.setItem('authToken', "login");
                localStorage.setItem('owner',response.data.owner);
                onLoginSuccess(true);
                navigate('/history');
            }else{
                setError('Wrong login info');
            }
            //setRecords(response.data);
        } catch (error) {
            console.error('Error fetching records:', error);
        }

    };



    return (
        <AppContainer>
            <LoginContainer>
                <Title>Login</Title>
                <form onSubmit={handleLogin}>
                    <Input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button type="submit">Login</Button>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                </form>
            </LoginContainer>
        </AppContainer>
    );
};

export default LoginScreen;
